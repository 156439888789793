<script setup>
import { computed, ref } from 'vue'

const items = ref([
  {
    route: { name: 'pharmasan.administrativa.solicitud-permisos.configuracion.motivos' },
    name: 'Motivos',
    description: 'Crear, editar y eliminar motivos de solicitud de permisos',
    icon: `
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
\t viewBox="0 0 512 512" xml:space="preserve">
<path style="fill:#439EE8;" d="M433.292,0H68.638c-4.391,0-7.95,3.56-7.95,7.95V504.05c0,4.391,3.56,7.95,7.95,7.95h364.654
\tc4.391,0,7.95-3.56,7.95-7.95V7.95C441.242,3.56,437.683,0,433.292,0z"/>
<path style="fill:#5CC4E0;" d="M443.362,0H78.708c-4.391,0-7.95,3.56-7.95,7.95V504.05c0,4.391,3.56,7.95,7.95,7.95h364.654
\tc4.391,0,7.95-3.56,7.95-7.95V7.95C451.313,3.56,447.753,0,443.362,0z"/>
<path style="fill:#439EE8;" d="M404.671,68.373H99.379c-4.391,0-7.95,3.56-7.95,7.95v394.335c0,4.391,3.56,7.95,7.95,7.95h305.292
\tc4.391,0,7.95-3.56,7.95-7.95V76.323C412.621,71.932,409.061,68.373,404.671,68.373z"/>
<path style="fill:#FFFFFF;" d="M413.681,59.362H108.389c-4.391,0-7.95,3.56-7.95,7.95v394.335c0,4.391,3.56,7.95,7.95,7.95h305.292
\tc4.391,0,7.95-3.56,7.95-7.95V67.313C421.631,62.922,418.072,59.362,413.681,59.362z"/>
<g>
\t<path style="fill:#FBC96C;" d="M193.193,232.149h-46.642c-4.391,0-7.95-3.56-7.95-7.95v-46.642c0-4.391,3.56-7.95,7.95-7.95h46.642
\t\tc4.391,0,7.95,3.56,7.95,7.95v46.642C201.143,228.589,197.583,232.149,193.193,232.149z"/>
\t<path style="fill:#FBC96C;" d="M193.193,321.193h-46.642c-4.391,0-7.95-3.56-7.95-7.95V266.6c0-4.391,3.56-7.95,7.95-7.95h46.642
\t\tc4.391,0,7.95,3.56,7.95,7.95v46.642C201.143,317.633,197.583,321.193,193.193,321.193z"/>
\t<path style="fill:#FBC96C;" d="M193.193,410.236h-46.642c-4.391,0-7.95-3.56-7.95-7.95v-46.642c0-4.391,3.56-7.95,7.95-7.95h46.642
\t\tc4.391,0,7.95,3.56,7.95,7.95v46.642C201.143,406.676,197.583,410.236,193.193,410.236z"/>
</g>
<g>
\t<path style="fill:#A29A9F;" d="M375.52,210.948H268.986c-4.391,0-7.95-3.56-7.95-7.95c0-4.391,3.56-7.95,7.95-7.95H375.52
\t\tc4.391,0,7.95,3.56,7.95,7.95C383.47,207.389,379.91,210.948,375.52,210.948z"/>
\t<path style="fill:#A29A9F;" d="M375.52,299.992H268.986c-4.391,0-7.95-3.56-7.95-7.95s3.56-7.95,7.95-7.95H375.52
\t\tc4.391,0,7.95,3.56,7.95,7.95S379.91,299.992,375.52,299.992z"/>
\t<path style="fill:#A29A9F;" d="M375.52,389.035H268.986c-4.391,0-7.95-3.56-7.95-7.95s3.56-7.95,7.95-7.95H375.52
\t\tc4.391,0,7.95,3.56,7.95,7.95S379.91,389.035,375.52,389.035z"/>
</g>
<g>
\t<path style="fill:#5293C4;" d="M163.516,210.949c-2.248,0-4.475-0.951-6.04-2.777l-25.441-29.681
\t\tc-2.858-3.334-2.472-8.352,0.862-11.21c3.333-2.858,8.352-2.471,11.21,0.862l20.664,24.109l87.347-63.525
\t\tc3.552-2.583,8.525-1.797,11.106,1.753c2.583,3.551,1.798,8.523-1.753,11.106l-93.284,67.843
\t\tC166.781,210.451,165.143,210.949,163.516,210.949z"/>
\t<path style="fill:#5293C4;" d="M163.516,299.993c-2.248,0-4.475-0.951-6.04-2.777l-25.441-29.681
\t\tc-2.858-3.334-2.472-8.352,0.862-11.21c3.333-2.858,8.352-2.471,11.21,0.862l20.664,24.109l87.347-63.525
\t\tc3.552-2.583,8.525-1.797,11.106,1.753c2.583,3.551,1.798,8.523-1.753,11.106l-93.284,67.843
\t\tC166.781,299.495,165.143,299.993,163.516,299.993z"/>
\t<path style="fill:#5293C4;" d="M163.516,389.035c-2.248,0-4.475-0.951-6.04-2.777l-25.441-29.681
\t\tc-2.858-3.334-2.472-8.352,0.862-11.21c3.333-2.858,8.352-2.471,11.21,0.862l20.664,24.109l87.347-63.525
\t\tc3.552-2.583,8.525-1.797,11.106,1.753c2.583,3.551,1.798,8.523-1.753,11.106l-93.284,67.843
\t\tC166.781,388.538,165.143,389.035,163.516,389.035z"/>
</g>
<path style="fill:#DB3D5A;" d="M321.988,33.921h-37.793c-7.867-8.128-18.615-12.72-30.05-12.72c-11.436,0-22.183,4.592-30.05,12.72
\th-37.793c-4.391,0-7.95,3.56-7.95,7.95v55.122c0,4.391,3.56,7.95,7.95,7.95h135.685c4.391,0,7.95-3.56,7.95-7.95V41.872
\tC329.938,37.481,326.378,33.921,321.988,33.921z"/>
<path style="fill:#FF675C;" d="M328.878,33.921h-37.793c-7.867-8.128-18.614-12.72-30.05-12.72c-11.436,0-22.183,4.592-30.05,12.72
\th-37.793c-4.391,0-7.95,3.56-7.95,7.95v55.122c0,4.391,3.56,7.95,7.95,7.95h135.685c4.391,0,7.95-3.56,7.95-7.95V41.872
\tC336.828,37.481,333.269,33.921,328.878,33.921z"/>
<path style="fill:#FFFFFF;" d="M206.443,78.443V60.422c0-2.927,2.373-5.3,5.3-5.3h28.228l6.249-6.458
\tc3.908-4.039,9.169-6.263,14.815-6.263c5.646,0,10.907,2.224,14.815,6.263l6.249,6.458h28.228c2.927,0,5.3,2.373,5.3,5.3v18.021
\tc0,2.927-2.373,5.3-5.3,5.3h-98.584C208.816,83.743,206.443,81.37,206.443,78.443z"/>
</svg>
        `
  },
  {
    route: { name: 'pharmasan.administrativa.solicitud-permisos.configuracion.empleados' },
    name: 'Empleados',
    description: 'Listado de Empleados para gestionar las solicitudes de permisos',
    icon: `
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
\t viewBox="0 0 387.596 387.596" xml:space="preserve">
<g>
\t<g>
\t\t<path style="fill:#FFDA79;" d="M128.29,131.372v33.601c0,18.472-14.99,33.346-33.346,33.346H70.058
\t\t\tc-18.361,0-33.346-14.879-33.346-33.346v-33.601c0-18.425,14.929-33.353,33.346-33.353h24.886
\t\t\tC113.361,98.018,128.29,112.947,128.29,131.372z"/>
\t\t<path style="fill:#51B3DA;" d="M128.29,131.372v6.876h-18.003c-22.247,0-40.229-18.035-40.229-40.229h24.886
\t\t\tC113.361,98.018,128.29,112.947,128.29,131.372z"/>
\t\t<path style="fill:#51B3DA;" d="M70.334,98.018c0,2.391,0.018,3.407-0.036,4.368c0,16.144-13.515,33.648-33.586,35.665v-6.68
\t\t\tC36.711,113.361,51.121,98.018,70.334,98.018z"/>
\t\t<path style="fill:#FF9392;" d="M91.709,231.352l11.767,41.668l-20.976,16.557l-20.976-16.557l11.767-41.668L61.525,218.96
\t\t\tc12.958-3.51,29.504-3.372,41.952,0L91.709,231.352z"/>
\t\t<path style="fill:#51B3DA;" d="M82.501,289.578h-75c0-33.782,22.902-62.215,54.024-70.617l11.767,12.392l-11.767,41.668
\t\t\tL82.501,289.578z"/>
\t\t<path style="fill:#51B3DA;" d="M82.501,289.578l20.976-16.557l-11.767-41.668l11.767-12.392
\t\t\tc30.96,8.342,54.024,36.697,54.024,70.617H82.501z"/>
\t</g>
\t<g>
\t\t<g>
\t\t\t<path style="fill:#FFFFFF;" d="M356.727,47.13v26.881c0,14.782-11.996,26.677-26.677,26.677h-19.909
\t\t\t\tc-14.687,0-26.677-11.901-26.677-26.677V47.13c0-14.74,11.943-26.683,26.677-26.683h19.909
\t\t\t\tC344.784,20.447,356.727,32.39,356.727,47.13z"/>
\t\t\t<path style="fill:#51B3DA;" d="M356.727,47.13v5.501h-14.403c-17.798,0-32.183-14.428-32.183-32.183h19.909
\t\t\t\tC344.784,20.447,356.727,32.39,356.727,47.13z"/>
\t\t\t<path style="fill:#51B3DA;" d="M310.362,20.447c0,0.098-0.029,3.5-0.029,3.5c-0.611,14.938-12.17,27.049-26.869,28.526V47.13
\t\t\t\tC283.464,32.722,294.992,20.447,310.362,20.447z"/>
\t\t\t<path style="fill:#C7E9F4;" d="M327.462,127.115l9.414,33.335l-16.781,13.245l-16.781-13.245l9.414-33.335l-9.414-9.914
\t\t\t\tc10.366-2.808,23.603-2.697,33.561,0L327.462,127.115z"/>
\t\t\t<path style="fill:#51B3DA;" d="M320.095,173.695h-60c0-27.026,18.322-49.772,43.219-56.494l9.414,9.914l-9.414,33.335
\t\t\t\tL320.095,173.695z"/>
\t\t\t<path style="fill:#51B3DA;" d="M320.095,173.695l16.781-13.245l-9.414-33.335l9.414-9.914
\t\t\t\tc24.776,6.676,43.219,29.367,43.219,56.494H320.095z"/>
\t\t</g>
\t\t<g>
\t\t\t<path style="fill:#FFFFFF;" d="M356.727,240.584v26.881c0,14.771-11.986,26.677-26.677,26.677h-19.909
\t\t\t\tc-14.679,0-26.677-11.893-26.677-26.677v-26.881c0-14.74,11.943-26.683,26.677-26.683h19.909
\t\t\t\tC344.784,213.901,356.727,225.844,356.727,240.584z"/>
\t\t\t<path style="fill:#51B3DA;" d="M356.727,240.584v5.501h-14.403c-17.798,0-32.183-14.428-32.183-32.183h19.909
\t\t\t\tC344.784,213.901,356.727,225.844,356.727,240.584z"/>
\t\t\t<path style="fill:#51B3DA;" d="M310.362,213.901c0,1.921,0.014,2.73-0.029,3.494c0,12.915-10.805,26.918-26.869,28.532v-5.344
\t\t\t\tC283.464,226.175,294.992,213.901,310.362,213.901z"/>
\t\t\t<path style="fill:#C7E9F4;" d="M327.462,320.568l9.414,33.335l-16.781,13.246l-16.781-13.246l9.414-33.335l-9.414-9.914
\t\t\t\tc10.366-2.808,23.603-2.698,33.561,0L327.462,320.568z"/>
\t\t\t<path style="fill:#51B3DA;" d="M320.095,367.148h-60c0-27.026,18.322-49.772,43.219-56.494l9.414,9.914l-9.414,33.335
\t\t\t\tL320.095,367.148z"/>
\t\t\t<path style="fill:#51B3DA;" d="M320.095,367.148l16.781-13.246l-9.414-33.335l9.414-9.914
\t\t\t\tc24.776,6.676,43.219,29.367,43.219,56.494H320.095z"/>
\t\t</g>
\t</g>
\t<g>
\t\t<path style="fill:#13829B;" d="M70.058,205.819h24.886c22.725,0,40.847-18.517,40.847-40.846c0-14.603,0-18.622,0-33.601
\t\t\tc0-22.527-18.324-40.854-40.847-40.854c-30.326,0-18.55,0-24.886,0c-22.523,0-40.847,18.327-40.847,40.854
\t\t\tc0,9.874,0,11.354,0,33.601C29.211,187.273,47.288,205.819,70.058,205.819z M120.774,130.748h-10.487
\t\t\tc-15.401,0-28.463-10.592-31.867-25.23C87.405,105.518,120.069,100.778,120.774,130.748z M62.244,106.728
\t\t\tc-1.974,9.785-8.789,17.941-17.865,21.731C45.512,118.383,52.537,109.815,62.244,106.728z M44.211,144.292
\t\t\tc11.124-2.9,20.564-9.988,26.583-19.42c8.618,12.682,23.148,20.876,39.493,20.876h10.503v19.225
\t\t\tc0,14.166-11.507,25.846-25.847,25.846H70.058c-14.392,0-25.847-11.718-25.847-25.846V144.292z"/>
\t\t<path style="fill:#13829B;" d="M105.446,211.724c-0.003-0.001-0.005-0.002-0.008-0.003c-0.003-0.001-0.006-0.002-0.01-0.002l0,0h0
\t\t\tc-13.929-3.77-31.928-3.77-45.858,0.001c-0.002,0.001-0.004,0.001-0.006,0.001c-0.002,0-0.003,0.001-0.005,0.002
\t\t\tC24.491,221.195,0,253.208,0,289.578c0,4.142,3.358,7.5,7.5,7.5c6.592,0,143.422,0,150,0c4.143,0,7.5-3.358,7.5-7.5
\t\t\tC165,253.193,140.511,221.181,105.446,211.724z M56.878,278.908l4.016,3.17H15.43c2.872-24.989,19.931-46.377,43.85-54.59
\t\t\tl5.635,5.934l-10.607,37.561C53.482,273.901,54.497,277.028,56.878,278.908z M82.5,280.023l-12.396-9.785l10.406-36.847
\t\t\tc0.719-2.547,0.043-5.284-1.779-7.203l-1.999-2.105c3.816-0.258,7.724-0.259,11.538,0l-1.999,2.105
\t\t\tc-1.822,1.919-2.498,4.656-1.779,7.203l10.406,36.847L82.5,280.023z M104.107,282.078l4.016-3.17
\t\t\tc2.381-1.879,3.396-5.007,2.571-7.926l-10.607-37.561l5.637-5.936c23.917,8.202,40.975,29.593,43.848,54.592H104.107z"/>
\t\t<path style="fill:#13829B;" d="M260.096,181.195c5.273,0,114.737,0,120,0c4.143,0,7.5-3.358,7.5-7.5
\t\t\tc0-32.768-24.378-61.55-58.187-65.507c20.603,0,34.817-16.221,34.817-34.177c0-11.682,0-14.897,0-26.88
\t\t\tc0-18.849-15.332-34.183-34.177-34.183c-31.431,0-14.787,0-19.909,0c-18.845,0-34.177,15.334-34.177,34.183
\t\t\tc0,7.899,0,9.083,0,26.88c0,17.885,14.123,34.177,34.819,34.177c-33.798,3.956-58.187,32.751-58.187,65.507
\t\t\tC252.596,177.837,255.953,181.195,260.096,181.195z M339.091,125.76c17.581,6.403,30.217,22.043,32.951,40.435h-30.346
\t\t\tc5.005-4.2,2.498-7.427-5.856-37.011L339.091,125.76z M320.096,164.14l-8.202-6.474c0.142-0.503,8.103-28.635,8.202-29.143
\t\t\tc0.095,0.5,8.062,28.65,8.201,29.144L320.096,164.14z M318.757,122.652c0.891-0.018,1.786-0.018,2.677,0
\t\t\tc-0.682,0.938-1.149,2.055-1.338,3.055C319.907,124.724,319.447,123.601,318.757,122.652z M349.121,45.131h-6.797
\t\t\tc-10.957,0-20.344-7.106-23.527-17.184C326.41,27.947,347.035,25.026,349.121,45.131z M301.648,29.957
\t\t\tc-1.767,5.126-5.353,9.446-9.994,12.148C293.159,36.602,296.873,32.329,301.648,29.957z M310.141,93.188
\t\t\tc-10.681,0-19.177-8.695-19.177-19.177V58.614c8.064-2.377,14.997-7.415,19.803-14.061c7.275,9.526,18.747,15.578,31.558,15.578
\t\t\th6.902v13.88c0,10.51-8.537,19.177-19.177,19.177H310.141z M301.101,125.762l3.25,3.422c-8.596,30.438-10.761,32.896-5.856,37.011
\t\t\tH268.15C270.885,147.812,283.521,132.173,301.101,125.762z"/>
\t\t<path style="fill:#13829B;" d="M329.403,301.641c20.647,0,34.823-16.252,34.823-34.177c0-11.682,0-14.897,0-26.88
\t\t\tc0-18.849-15.332-34.183-34.177-34.183h-19.689h-0.221c-18.845,0-34.177,15.335-34.177,34.183c0,7.899,0,9.083,0,26.88
\t\t\tc0,17.882,14.119,34.177,34.822,34.177c-33.819,3.956-58.19,32.77-58.19,65.507c0,4.142,3.357,7.5,7.5,7.5
\t\t\tc5.273,0,114.737,0,120,0c4.143,0,7.5-3.358,7.5-7.5C387.596,334.376,363.213,305.595,329.403,301.641z M298.494,359.648H268.15
\t\t\tc2.735-18.383,15.371-34.022,32.951-40.433l3.25,3.422C295.624,353.54,293.705,355.63,298.494,359.648z M349.121,238.585h-6.797
\t\t\tc-10.957,0-20.344-7.106-23.527-17.184C325.989,221.401,347.026,218.398,349.121,238.585z M301.648,223.411
\t\t\tc-1.767,5.126-5.353,9.446-9.994,12.148C293.159,230.054,296.873,225.782,301.648,223.411z M310.141,286.641
\t\t\tc-10.702,0-19.177-8.715-19.177-19.177v-15.397c8.064-2.377,14.997-7.415,19.803-14.061c7.275,9.526,18.747,15.578,31.558,15.578
\t\t\th6.902v13.88c0,10.511-8.537,19.177-19.177,19.177H310.141z M321.434,316.106c-0.671,0.923-1.145,2.031-1.338,3.055
\t\t\tc-0.191-0.994-0.653-2.111-1.339-3.055C319.647,316.088,320.543,316.088,321.434,316.106z M320.096,357.594l-8.202-6.474
\t\t\tc0.142-0.503,8.103-28.635,8.202-29.143c0.095,0.5,8.062,28.65,8.201,29.144L320.096,357.594z M341.696,359.648
\t\t\tc5.042-4.232,2.15-8.66-5.856-37.011l3.251-3.424c17.581,6.404,30.217,22.043,32.951,40.435H341.696z"/>
\t\t<path style="fill:#13829B;" d="M229.334,88.054c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5h-28.036
\t\t\tc-4.143,0-7.5,3.358-7.5,7.5v110.999h-20.536c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h20.536v100.489
\t\t\tc0,4.142,3.357,7.5,7.5,7.5h28.036c4.143,0,7.5-3.358,7.5-7.5c0-4.142-3.357-7.5-7.5-7.5h-20.536V88.054H229.334z"/>
\t</g>
</g>
</svg>
        `
  }
])

const getItems = computed(() => items.value)

</script>

<template>
  <section class="intro-x">

    <div class="my-5">
      <h1 class="font-bold text-xl">Configuración</h1>
    </div>

    <div class="grid lg:grid-cols-3 gap-4 my-3 grid-cols-1">
      <router-link
        :to="row.route"
        v-for="(row,k) in getItems"
        :key="k"
        class="box w-full rounded-3xl p-4 flex justify-center flex-col items-center zoom-in"
      >
        <div v-html="row.icon" class="bg-gray-800 p-2 rounded-xl" style="width: 75px; height: 75px"/>
        <div class="mt-2 mb-1 font-bold text-base">{{row.name}}</div>
        <div class="text-gray-500">{{row.description}}</div>
      </router-link>
    </div>

    <router-view />
  </section>
</template>

<style scoped>

</style>
